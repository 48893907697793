// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$kwalee-yellow: #ffcb05;
$white: #fff;
$black: #000;
$blue: #007bff;
$cyan: #17a2b8;

$primary: $kwalee-yellow;
$secondary: $black;
$info: $cyan;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $cyan,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1740px,
) !default;

// Links

$link-color: $blue;

// Typography

$font-family-sans-serif: Roboto, sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 1.65 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.35 !default;
$h4-font-size: $font-size-base * 1.2 !default;
$h5-font-size: $font-size-base * 1.05 !default;
$h6-font-size: $font-size-base !default;

// Buttons + Forms
$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1.75rem !default;
$input-btn-font-size: $font-size-base !default;

$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: 0.375rem !default;
$input-btn-padding-x-sm: 1.375rem !default;

$input-btn-padding-y-lg: 0.925rem !default;
$input-btn-padding-x-lg: 1.925rem !default;

$input-btn-border-width: 0 !default;

// Buttons
$btn-hover-colors: () !default;
$btn-hover-colors: map-merge(
  (
    "primary": $black,
    "secondary": $kwalee-yellow,
    "success": $black,
    "info": $black,
    "warning": $black,
    "danger": $black,
    "light": $black,
    "dark": $kwalee-yellow,
  ),
  $btn-hover-colors
);

$btn-hover-outline-color: $white !default;
$btn-hover-outline-border-color: $black !default;
$btn-hover-outline-width: 0.1rem !default;

$btn-box-shadow: none !default;

$btn-border-radius: 10rem !default;
$btn-border-radius-sm: $btn-border-radius !default;
$btn-border-radius-lg: $btn-border-radius !default;

// Forms
$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-group-addon-bg: $input-bg !default;

// Tables
$table-dark-bg: $black !default;
$table-dark-border-color: lighten($table-dark-bg, 20%) !default;
