.banner {
  position: relative;
  min-height: 200px;
  margin-bottom: 60px;
  max-height: 1000px;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .banner-homecaption {
    width: 100%;
    z-index: 9;
    font-size: 20px;
    padding: 10px 0;

    .bannerContentPanel {
      display: flex;
      padding: 0 40px;
      justify-content: space-between;
      align-items: center;
      margin: 10px;

      .bannerContentPanel__center {
        padding: 0 15px;
        text-align: center;

        p {
          max-width: 800px;
          margin: 0 auto;
          font-size: 16px;

          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
      }

      @media (max-width: 767px) {
        flex-wrap: wrap;

        .bannerContentPanel__left, .bannerContentPanel__right {
          img {
            max-width: 120px;
          }
        }

        .bannerContentPanel__center {
          margin-top: 25px;
          order: 3;
        }
      }
    }
  }
}

.buttons-section .btn {
  margin-bottom: 30px;
}

.icon-mobile .fa {
  font-size: 28px;
}

.process__item {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .process__block {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    flex: 1;
  }

  .process__title {
    text-align: center;
    margin: 0 0 30px;
    min-height: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .process__text {
    font-size: 18px;
    padding: 20px 30px;
    min-height: 160px;
  }

  .process__text p:last-child {
    margin-bottom: 0;
  }

  .process__img {
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    video {
      width: 100%;
      object-fit: fill;
    }

    img {
      width: 100%;
    }

    figure {
      margin: 0;
    }
  }
}
