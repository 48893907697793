.logo-countdown {
  display: block;
  position: relative;

  > div {
    position: absolute;
    right: 0;
    bottom: 0;

    transform: rotate(336deg) translate(10px, -20px);

    color: #16264F;
    font-weight: bold;
    font-size: 25px;
  }
}

.bannerContentPanel .hypercasual-heroes {
  .side-panel {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;

    & > div {
      flex: 1;
      min-width: 100%;
    }

    .side-panel {
      flex-direction: column;
      justify-content: center;

      &:first-child {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;

    & > div {
      flex: 1;
      min-width: 100%;
    }

    .side-panel {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
