.adminPanelWrap {
  background: #ffcb05 url("../../media/layout/body-bottom.png") no-repeat 0 bottom;
  padding: 30px 0 0;
  position: relative;
  min-height: 100vh;
  background-size: 100%;

  @media (max-width: 991px) {
    .container {
      max-width: 100%;
    }
  }
}

.adminTableWrapper {
  background: #fff;
  max-height: calc(100vh - 245px);
  overflow: auto;
  margin-bottom: 10px;

  table {
    margin-bottom: 0;
  }

  table, th, tr, td {
    border: 1px solid #dedfe1;
    border-collapse: collapse;
  }

  .thead-dark th {
    background: #030404;
    color: #fff;
    padding: 20px 15px;
    vertical-align: middle;
  }

  tbody {
    th, td {
      vertical-align: middle;
      padding: 30px 15px;
      background: #fff;
      word-break: break-word;
    }

    td {
      padding: .75rem;
    }
  }
}

.approvedBtn {
  color: green;
}

.rejectedBtn {
  color: red;
}

.waitingBtn {
  color: #ffcb05;
}

.feedbackText p {
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
}

.game_noti {
  margin-top: 100px;
  text-align: center;
}
