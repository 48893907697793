.checkboxbtn {
  position: relative;

  label {
    padding: 0 0 0 37px;
    position: relative;
    line-height: 20px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 15px;
    color: #030404;

    a {
      color: #030404;
      text-decoration: underline;
    }
  }

  input[type="checkbox"] {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;

    & + label:before {
      content: "";
      -webkit-appearance: none;
      background-color: #ffffff;
      border: 1px solid #f1f1f1;
      padding: 9px;
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      left: 0;
      top: 0;
      border-radius: 5px;
    }

    &:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 7px;
      height: 14px;
      border: solid #000000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.checkboxbtn-group {
  margin: 0 0 30px;
}
