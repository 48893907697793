.custom-radiobtn {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 0 0 7px;

  &.radio-inline .custom-radiobtn {
    min-width: 120px;
    float: left;
    margin-right: 15px;
    width: auto;
  }

  [type="radio"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;

    & + label {
      position: relative;
      padding: 0 0 0 35px;
      cursor: pointer;
      line-height: 1.4;
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      color: #616161;
      font-size: 18px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #ffffff;
      }

      &:after {
        content: "";
        width: 8px;
        height: 8px;
        background: #000000;
        position: absolute;
        top: 8px;
        left: 6px;
        border-radius: 100%;
        transition: all 0.2s ease;
        opacity: 0;
        transform: scale(0);
      }
    }

    &:checked + label {
      color: #000000;

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
