.app-icon-list {
  padding: 0;
  margin: 0 0 38px;

  li {
    list-style: none;
    margin: 0 0 10px;
    padding: 0 0 0 28px;
    position: relative;
  }

  a {
    color: #000000;
  }

  .list-icon {
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
  }
}
