.mygame-pages {
  padding: 35px 0 0;

  .gamedetail-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    &.view-mode-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .card {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &.view-mode-list {
      a {
        display: block;
        width: 100%;
        height: 50px;
        max-height: 50px;

        border-radius: 5px;
        overflow: hidden;

        border: 1px solid #999;

        margin-bottom: 10px;
      }

      img {
        display: inline-block;
        height: inherit;
        border-radius: 5px;
        margin-right: 10px;
      }

      div {
        display: inline-block;
      }
    }

    @media (max-width: 767px) {
      &.view-mode-grid {
        justify-content: center;
      }
    }
  }
}

.view-mode {
  text-align: right;

  button {
    padding: 0;
    margin: 5px;
    background: transparent;
    border: none;
    color: #999;

    &.selected {
      color: #000;
    }

    &:not(.selected):hover {
      color: #777;
    }
  }
}

.gamedetail {
  box-shadow: 0 0 6px rgb(0 0 0 / 16%);
  position: relative;
  overflow: hidden;
  margin: 0 0 34px;
  padding: 25px 75px 25px 25px;
  border-radius: 5px;

  .gamedetail__imgblock {
    float: left;
    text-align: center;
    margin-right: 4.5%;

    h3 {
      font-size: 16px;
      margin: 0;
    }
  }

  .gamedetail__date {
    float: left;
  }

  .gamedetail__progress {
    float: right;
    width: 44.5%;

    .progressbar-text {
      font-size: 17px;
      margin: 15px 0 0 0;

      img {
        margin-right: 9px;
      }
    }
  }

  .gamedetail__readmore {
    position: absolute;
    right: 0;
    width: 54px;
    background: #f1f1f1;
    height: 100%;
    top: 0;

    &:hover, &:focus {
      background: #ffcb05;
      text-decoration: none;
    }

    i {
      position: absolute;
      top: 50%;
      text-align: center;
      color: #000000;
      font-size: 44px;
      font-weight: normal;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
    }
  }

  @media (max-width: 767px) {
    padding: 25px;

    .gamedetail__imgblock {
      display: contents;
    }

    .gamedetail__date {
      margin: 30px 0 0;
    }

    .gamedetail__progress {
      margin-bottom: 50px;
    }

    .gamedetail__readmore {
      width: auto;
      left: 0;
      bottom: 0;
      height: 54px;
      border-radius: 0;
      top: auto;
    }
  }

  @media (max-width: 991px) {
    .gamedetail__progress {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.gamedetail__img {
  display: block;
  width: 130px;
  height: auto;
  margin: 0 0 14px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  overflow: hidden;
}

.gamedetail__video {
  float: left;
  clear: both;
  position: relative;
  width: 370px;
  max-width: 100%;

  video {
    width: 100%;
    height: 200px;
    background: $black;
    display: block;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin: 0 0 30px;
  }
}

.game-date {
  font-size: 18px;
  font-weight: 500;
  padding: 0;

  li {
    list-style: none;
    margin: 0 0 22px;

    span {
      line-height: 1;
      margin: 0 9px 0 0;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.gamebar-status {
  width: 100%;
  display: block;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 45px;

  @media (max-width: 991px) {
    text-align: left;
    margin: 0 0 10px;
  }
}

.singlegame-img {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .gamedetail__img {
    width: 80px;
    margin: 0 15px 0 0;
    min-width: 80px;
  }
}

.singlegraph-left {
  float: left;

  ul {
    padding: 0;

    li {
      list-style: none;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}
