body #awn-popup-wrapper {
  .awn-popup-title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .awn-popup-body {
    font-size: 16px;
  }

  .awn-buttons {
    .awn-btn {
      background: #000000;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      margin: 10px 0;
      border: 2px solid #000000;
      transition: all 0.3s ease 0s;
      border-radius: 60px;
      cursor: pointer;
      line-height: 41px;
      min-width: 100px;
      padding: 0 20px;
      height: 45px;

      &.awn-btn-cancel {
        background: #ffffff;
        color: #000;
      }
    }

    .awn-btn-success:hover {
      background: #ffffff;
      color: #000000;
    }

    .awn-btn-cancel:hover {
      background: #000000;
      color: #ffffff;
    }
  }
}
