.your-organisation {
  .editorganizationLogo.avatar-upload {
    margin: 0 0 40px;
    width: 100%;
    background: #ffde61;
    border: 1px dashed #000000;
    padding: 40px;
    border-radius: 5px;
    text-align: center;
  }

  .white-box {
    max-height: 120px;
    overflow-y: auto;
    padding-left: 25px;
  }
}
