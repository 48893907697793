.welcome-message {
  max-width: 520px;
  border-radius: 4px;
  padding: 24px;
  text-align: center;

  > * {
    animation-fill-mode: both;
  }

  .coins {
    font-size: 50px;

    margin-bottom: 20px;

    position: relative;

    animation-duration: 1.5s;
    animation-name: welcomeFadeInTop;

    .badge {
      position: absolute;
      top: -20px;
      right: -20px;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      width: 50px;
      height: 50px;

      border-radius: 50%;

      background: #ff0000;
    }
  }

  h1 {
    animation-duration: 2.5s;
    animation-name: welcomeFadeInLeft;
  }

  h2 {
    animation-duration: 2.5s;
    animation-name: welcomeFadeInRight;
  }

  button {
    animation-duration: 3.5s;
    animation-name: welcomeFadeInBottom;
  }
}

.cssanimation.paused {
  animation-play-state: paused;
}

@keyframes welcomeFadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  33% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes welcomeFadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  71% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes welcomeFadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes welcomeFadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
  }
}
