.progressbar-linear {
  margin: 7px 0;
  padding: 0;
  width: 100%;
  height: 13px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;

  .bar {
    position: relative;
    float: left;
    height: 100%;
    background: #008000;
    border-radius: 10px;
    transition: width .5s ease-in-out;
  }
}

.progressbar-text {
  font-size: 18px;
}

.progressbar-top {
  margin-bottom: 34px;
}

.progressbar-top .progressbar-linear {
  margin: 7px 0 10px;
}
