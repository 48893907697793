.gdpr-banner {
  z-index: 10;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: #000;
  color: #fff;
  padding: 20px;

  h1 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }
}

.gdpr-modal .gdpr-modal-dialog {
  display: flex;
  padding: 0;

  .MuiTabs-indicator {
    display: none;
  }

  .tab-container {
    background: #ccc;

    height: 300px;
    overflow: auto;
  }

  .tab {
    width: 80px;
    background: #ccc;
    text-transform: none;
  }

  .tab.Mui-selected {
    background: #fff;
  }

  .tab-pane {
    flex: 1;
    padding: 10px;

    height: 300px;
    overflow: auto;
  }
}
