.gameDashboard {
  .tests {
    .chart {
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 30px;
    }

    .game-date li span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      color: #FFCB01;
    }

    video {
      display: block;
      background-color: #000;
      border-radius: 5px;

      > div {
        font-weight: 500;
      }
    }

    .gamedetail {
      height: auto;
    }

    .gamedetail__status {
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    .gamedetail {
      flex-direction: column;
      padding-bottom: 50px !important;

      .video {
        margin-right: 0 !important;

        video {
          width: 100%;
        }
      }

      .gamedetail__status {
        display: none;
      }

      > .d-flex {
        flex: 1;
        margin-top: 10px;
        justify-content: center;
      }
    }
  }
}

.video-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    width: 400px;
    margin: 0 20px 20px 0;

    &.best {
      border: 3px solid #ffcb05;
    }

    video {
      width: 100%;
      height: 300px;
      background-color: #000;
    }
  }
}
