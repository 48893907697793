.priceright {
  font-size: 20px;

  img {
    margin: 0 0 0 7px;
  }
}

.game1__desc {
  font-size: 13px;
  margin: 5px 0 30px;
}
