.footer {
  background: #fff;
  padding: 20px 0 0;

  .copy-right {
    font-size: 14px;
    color: $text-muted;
    text-align: center;
    padding: 15px 0;
    border-top: 1px solid #dedede;
    margin: 20px 0 0;

    p {
      margin: 0;
    }
  }
}
