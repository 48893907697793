.uploadphoto__img {
  display: block;
  border-radius: 5px;
  width: 180px;
  height: 170px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
}

.uploadphoto {
  width: 180px;
  position: relative;
  margin: 20px 0 20px;
}

.uploadphoto__text {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  width: 100%;
  left: 0;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  vertical-align: middle;
  color: #ffffff;
  cursor: pointer;

  label {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;
    width: 100%;
    padding: 5px 10px;
  }

  input {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    left: 0;
    top: 0;
  }

  a {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  img {
    margin-right: 10px;
  }
}

.avatar-upload {
  position: relative;
  margin: 40px auto 20px;
  width: 210px;

  small {
    color: #6d6d6d;
  }

  .avatar-edit input {
    display: none;

    + label {
      display: inline-block;
      width: 100%;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: normal;
      transition: all 0.2s ease-in-out;
      vertical-align: top;

      font-size: 22px;
      text-transform: capitalize;
      position: static;

      img {
        margin-right: 15px;
      }

      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .avatar-preview {
    position: relative;
    border-radius: 100%;

    margin: 0 10px;

    canvas {
      background-size: contain;
      background-position: center center;
    }
  }
}

.avatar-show {
  position: relative;
  margin: 0 0 40px;
  width: 100%;
  padding: 40px;
  border-radius: 5px;
  text-align: center;
}

.avatar-preview {
  min-width: 134px;
  width: 134px;
  height: 134px;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 20px;

  > div,
  > img,
  > svg {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: 7px;
    border: 1px solid #e5e5e5;
    fill: #ccc;
  }
}
