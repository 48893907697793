.modal-custom {
  .modal-content {
    border: none;
    width: 100%;
  }

  .modal-header {
    border-bottom: 1px solid #e9ecef;
    background: #ffcb05;
    border-radius: 5px 5px 0 0;
    padding: 15px 15px 15px 25px;

    h2 {
      margin: 0;
    }
  }

  .modal-body {
    padding: 55px 40px;
  }

  .submit-btn-group {
    margin: 45px 0 0;
  }

  @media (max-width: 767px) {
    .submit-btn-group {
      margin: 25px 0 0;
    }

    .modal-body {
      padding: 30px;
    }
  }
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));

  @media (min-width: 576px) {
    min-height: calc(100% - (1.75rem * 2));
    margin: 1.75rem auto;
  }
}
