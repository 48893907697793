.header {
  background: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

  .navbar {
    padding: 0;
  }

  .navbar-title {
    margin-top: 5px;
  }

  .header-top {
    position: relative;
    background: $primary;
    padding: 7px 0 7px;
    transition: all 0.3s ease;

    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }

  .nav-actions {
    a {
      height: 36px;

      + a {
        margin-left: 20px;
      }

      &.circled {
        width: 36px;
        background: $white;
        border-radius: 50%;
        color: $black;
        display: inline-flex;
        font-size: 18px;
        position: relative;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $primary;
        }
      }

      .fa-check-square-o {
        padding-left: 2px;
      }
    }
  }
}

.navbar-brand {
  transition: all 0.3s ease;
  width: 158px;
  margin-right: 18px;
  position: relative;

  > div {
    position: absolute;
    right: 0;
    bottom: 0;

    transform: rotate(336deg) translate(30px, 15px);

    font-family: "Comic Sans MS", "Courier New";

    color: black;
    font-weight: bold;
    font-size: 25px;

    @supports (-webkit-text-stroke: 2px black) {
      -webkit-text-stroke: 2px black;
      -webkit-text-fill-color: red;
    }
  }
}

.navigation {
  padding: 0;
  align-items: flex-start;
  margin: 0 6px 0 0;
  transition: all 0.3s ease;

  > li.nav-item-superuser {
    border-left: 2px solid #666;
    padding-left: 50px;

    ~ .nav-item-superuser {
      border-left: none;
      padding-left: inherit;
    }
  }

  li {
    margin-right: 25px;
    margin-left: 5px;
    text-align: center;

    a {
      color: $dark;
      padding: 0;
      font-weight: 500;
      transition: all 0.3s ease;

      &.active,
      &:hover,
      &:focus {
        color: $primary;
        outline: none;
      }
    }
  }
}

.navbar-toggler {
  margin-left: auto;

  .icon-bar {
    display: block;
    width: 30px;
    height: 3px;
    background: $dark;
    transition: all 0.3s ease;

    + .icon-bar {
      margin-top: 5px;
    }
  }

  &:hover,
  &:focus {
    .icon-bar {
      background: $primary;
    }
  }
}

.account__tier {
  position: absolute;
  top: -7px;
  left: 100%;
  padding: 0 1px;
  min-width: 24px;
  margin-left: -13px;
}

@media (max-width: 991px) {
  .header {
    .navbar-title {
      font-size: 16px;
    }
  }

  .header-midheading {
    position: absolute;
    left: 0;
    top: 100%;
    color: #000000;
    z-index: 1;
    text-align: left;
    padding: 0 15px;
    width: 660px;
    right: 65px;
    margin: 0 auto;
  }

  .navigation {
    $topBorderStyle: 1px solid #eaeaea;

    > li.nav-item-superuser {
      border-top: 2px solid #666;
      border-left: none;
      padding-left: inherit;

      ~ .nav-item-superuser {
        border-top: $topBorderStyle;
      }
    }

    li {
      margin: 0;
      width: 100%;
      border-top: $topBorderStyle;

      .nav-link {
        padding: 10px 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .header-midheading {
    width: 475px;
  }
}

@media (max-width: 576px) {
  .header-midheading {
    width: auto;
    right: 45px;
  }
}
