.full-page {
  height: 100%;
  background: $primary;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 165px;
    content: "";
    background: url("../../media/layout/body-top.png") no-repeat right -215px $primary;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 165px;
    content: "";
    background: url("../../media/layout/body-bottom.png") no-repeat 0 bottom $primary;
    background-size: 100% auto;
  }

  .full-page-section {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    align-items: center;
    overflow-y: auto;

    h1,
    h2 {
      color: $black;
      margin: 34px 0;
    }
  }

  .full-page-form {
    max-width: 100%;
    padding: 40px 60px;

    hr {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      border: 0;
      border-bottom: 1px solid #fede65;
      border-top: 1px solid #c89f02;
      width: 86%;
    }
  }

  .full-page-form-container {
    width: 380px;
    margin: 0 auto;
    max-width: 100%;
  }
}

.full-page-head {
  text-align: center;
  display: block;
}

.full-page-head .navbar-brand {
  display: inline-block;
  vertical-align: top;
  float: none;
  padding: 0;
  max-width: 100%;
  width: auto;
}

.full-page-headed {
  background: url("../../media/layout/body-bottom.png") no-repeat 0 bottom $primary;
  padding: 80px 0 100px;
  position: relative;
  background-size: 100%;

  flex: 1 0 0;

  .radiobtn-group {
    margin: 0 0 25px;
  }

  .checkboxbtn {
    label {
      padding: 0px 0 0 40px;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.34;
      margin-bottom: 16px;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    input[type="checkbox"] {
      + label:before {
        top: 3px;
      }

      &:checked + label:after {
        top: 4px;
      }
    }
  }
}
