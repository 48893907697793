.video-selector, .white-box.video-selector {
  min-width: 230px;
  padding: 5px;
  overflow-x: auto;
  white-space: nowrap;

  ol {
    padding: 0;
  }

  li {
    display: inline-flex;
    flex-direction: column;
    margin: 5px;
    list-style-type: none;
    border: 1px solid gray;
    border-radius: 5px;
    position: relative;

    &:last-child {
      margin-right: 10px;
    }

    .title {
      padding: 5px;
      cursor: pointer;
    }

    .upload_status {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      color: #fff;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.retry {
        cursor: pointer;
      }
    }

    &.selected .title {
      background-color: rgba(0, 128, 0, 0.2);
    }

    &.disabled .title, &.banned .title {
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.5);
    }
  }

  video {
    min-width: 200px;
    height: 200px;
    margin-bottom: -5px;
  }
}
