.table--primary {
  thead tr th {
    background: #000;
    color: #fff;
    padding: 22px 20px;
    font-weight: 500;
    border-top: none;

    &:not(:last-of-type) {
      border-right: 1px solid #e8e8e8;
    }
  }

  tbody tr {
    background: #fff;

    td {
      padding: 22px 20px;

      &:not(:last-of-type) {
        border-right: 1px solid #e8e8e8;
      }
    }
  }
}

.table tr.highlight td {
  background-color: rgba(255, 203, 0, 0.3);
}

th.invalid, td.invalid {
  background-color: #ffe6e6 !important;
}
