.funnel {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 5px 10px 10px 10px;

  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);

  .funnel-node {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 5px 5px 0 5px;

    &.split-node {
      flex-direction: row;
    }

    &.first-node {
      padding-left: 0;
    }

    &.last-node {
      padding-right: 0;
    }

    .funnel-bar {
      flex-shrink: 0;
      width: 100%;
      height: 30px;
      background: #1f96d7;

      border-radius: 5px;

      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;

      padding: 3px;

      position: relative;

      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        top: 0;
        left: 0;

        transition: background-color .2s ease-in-out;
      }

      &:hover {
        &:after {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
