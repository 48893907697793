@import "misc";

#wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;

  padding: 0;
}

img {
  max-width: 100%;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: initial;
}

.submit-btn-group {
  margin: 25px 0 15px;
}

.ReactCrop {
  width: 100%;
}

.ReactCrop__image {
  width: 100%;
}

.white-box {
  background: $white;
  border-radius: 5px;
  min-height: 50px;
  padding: 10px 10px 10px 20px;

  ul, ol {
    padding: 0 0 0 15px;
  }
}

.shadowed-box {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 30px;
  overflow: hidden;
  margin: 15px 0 34px;
}

.instructionPanel img {
  margin-bottom: 20px;
}

.coin-balance {
  display: block;
  text-align: center;

  margin-bottom: 20px;

  img {
    margin: 0 0 15px;
  }
}

.commonBackground {
  background: #ffcb05 url("../media/layout/body-bottom.png") no-repeat 0 bottom;
  padding: 30px 0 0;
  position: relative;
  min-height: 100vh;
  background-size: 100%;
}

.breakdown-table-heading {
  padding: 1rem 0;
}

.cursor-pointer {
  cursor: pointer;
}

.projectView .nav-pills {
  margin-bottom: 30px;

  .nav-link.active,
  .nav-link.active:hover {
    background-color: black;
    color: white;
  }
}

.form-control,
.MuiInput-root {
  border: 1px solid transparent;

  &.invalid:not(:disabled) {
    background-color: #ffe6e6;
    border: 1px solid #d79f9f;
  }
}

.form-control.invalid-number:not(:disabled) {
  background-color: #ffe6e6 !important;
}

.border-radius-0 {
  border-radius: 0;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.confirmbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.confirmbox-title-container {
  font-size: 20px;
  font-weight: bolder !important;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  padding: 0px !important;
}

.confirmbox-btn {
  background: #000 !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  margin: 10px !important;
  border: 2px solid #000 !important;
  transition: all 0.3s ease 0s !important;
  border-radius: 60px !important;
  cursor: pointer !important;
  line-height: 41px !important;
  min-width: 100px !important;
  padding: 0 20px !important;
  height: 45px !important;
}

.confirmbox-btn-success {
  background: #000;
  color: #fff;
}

.confirmbox-btn-success:hover {
  background: #fff !important;
  color: #000 !important;
}

.confirmbox-btn-cancel {
  background: #fff !important;
  color: #000 !important;
}

.confirmbox-btn-cancel:hover {
  background: #000 !important;
  color: #fff !important;
}

.confirmbox-title-text {
  font-weight: bolder;
}

.confirmbox-content {
  color: #000;
  text-align: center;
}

.confirmbox-buttons {
  display: flex;
  margin-top: 20px;
}

.confirmbox-icon {
  font-size: 45px;
  padding: 0px;
}

.custom-capitalize {
  text-transform: capitalize;
}

.custom-helper-text {
  font-size: 0.8rem;
  margin-top: 15px;
}

.mui-button-icon {
  font-size: 22px !important;
}

.mui-loader {
  color: black !important;
  margin-right: 15px;
}

.mui-icon-textfield {
  box-shadow: none !important;
}

.mui-textfield-icon {
  color: #495057;
  margin-left: 20px;
}

.nav-button {
  background-color: #ffcb05;
  border: none;
  border-radius: 0.25rem;
  color: #343a40;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px;
  width: 100%;
}

.nav-button-active {
  background-color: black;
  color: white;
}

.fab-spacer {
  margin-bottom: 85px;
}
