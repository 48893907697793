.password-strength {
  font-size: 12px;
}

.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 8px;

  &::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }

  &::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
}

.password-strength-meter-label {
  font-size: 14px;
}

.strength-Weak::-webkit-progress-value {
  background-color: #f25f5c;
}

.strength-Fair::-webkit-progress-value {
  background-color: #ffe066;
}

.strength-Good::-webkit-progress-value {
  background-color: #247ba0;
}

.strength-Strong::-webkit-progress-value {
  background-color: #70c1b3;
}
