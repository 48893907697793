.react-tel-input {
  .flag-dropdown {
    border: none !important;
    z-index: 6 !important;
  }

  .selected-flag:hover,
  .selected-flag:focus,
  .flag-dropdown,
  .flag-dropdown.open,
  .flag-dropdown.open .selected-flag {
    background: inherit !important;
  }

  .form-control {
    height: inherit;
  }
}

.MuiDialog-root .react-tel-input {
  .form-control {
    font-size: inherit !important;
    padding: 25px 0 25px 60px !important;
    border: none;
  }

  .flag-dropdown {
    .selected-flag {
      padding-left: 18px;

      .flag {
        transform: scale(1.2);
      }
    }

    .country-list {
      top: -200px;
      margin: -10px 0 10px -1px;

      height: 200px;
    }
  }
}
