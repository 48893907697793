.status-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: red;
  border: 9px solid #ffffff;
  box-shadow: 0 0 2px red;

  &.orange {
    background: #ffa640;
    box-shadow: 0 0 0 1px #ffa640;
  }

  &.green {
    background: #16c839;
    box-shadow: 0 0 0 1px #16c839;
  }

  &.grey {
    background: #8e8e8e;
    box-shadow: 0 0 0 1px #8e8e8e;
  }
}
