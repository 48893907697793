.coachingPanel {
  a {
    color: inherit;

    transition: 0.5s ease all;

    i {
      text-shadow: 0 10px 14px rgb(0 0 0 / 15%);
    }

    &:hover {
      text-decoration: inherit;

      box-shadow: 0 10px 14px rgb(0 0 0 / 15%);
    }
  }
}

.coachingPanel {
  a {
    color: inherit;

    transition: 0.5s ease all;

    i {
      text-shadow: 0 10px 14px rgb(0 0 0 / 15%);
    }

    &:hover {
      text-decoration: inherit;

      box-shadow: 0 10px 14px rgb(0 0 0 / 15%);
    }
  }
}

.coaching-wrapper {
  padding: 40px 0;
}

.coachingTopicBox-img {
  background: #fff;
  height: 274px;
  overflow: hidden;
  border-radius: 5px;
  transition: 0.5s ease all;

  video,
  img,
  iframe {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  video {
    background: #000;
  }
}

.coachingTopicDetail h2 {
  font-weight: 500;
  text-align: center;
  padding: 20px;
}

.coachingTopicPanel {
  padding-top: 30px;
}

.coachingTopicBox {
  margin-bottom: 30px;

  h3 {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
  }

  &:hover .coachingTopicBox-img {
    box-shadow: 0 10px 14px rgb(0 0 0 / 15%);
  }

  a,
  a:hover,
  a:active {
    color: #030404;
  }
}

.coachingBlogDetail {
  background: #fff;
  padding: 30px;
}

.coachingBlogDetail-content {
  > ul > li {
    margin-top: 2em;
  }

  > ol > li {
    margin-top: 2em;
  }

  h3 {
    color: #444;
  }

  .gallery {
    display: flex;
    justify-content: space-evenly;

    > * {
      flex: 1;
      margin: 5px;
      border: 2px solid #ccc;
    }
  }

  > img,
  .gallery,
  iframe {
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  .image-preview-left,
  .image-preview-right,
  .image-preview-center {
    padding: 5px;
    border: 1px solid #ccc;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .image-preview-left {
    float: left;
    margin-right: 1em;
  }

  .image-preview-right {
    float: right;
    margin-left: 1em;
  }

  .image-preview-center {
    display: block;
    margin: 1em auto;
  }

  iframe {
    width: 100%;
  }
}

.coachingBlog-wrapper {
  padding-bottom: 30px;
}

.coachingBlogDetail__right {
  .public-DraftStyleDefault-ltr,
  .public-DraftStyleDefault-rtl {
    text-align: inherit;
  }
}
