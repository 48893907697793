.btn {
  font-weight: bold;
  text-transform: uppercase;

  &:hover, &:focus, &.hover, &.focus {
    text-decoration: none;
  }

  &.btn-hover-outline:not(:disabled):not(.disabled) {
    &:hover, &:focus, &.hover, &.focus {
      color: color-yiq($btn-hover-outline-color);
      background: $btn-hover-outline-color;
      box-shadow: inset 0 0 0 $btn-hover-outline-width $btn-hover-outline-border-color !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    $background: map-get($btn-hover-colors, $color);

    @include button-variant($value, $value, $background, darken($value, 10%), $background);

    &:focus, &.focus {
      box-shadow: none !important;
    }
  }

  .btn-outline-#{$color} {
    border-width: 2px;
  }
}
