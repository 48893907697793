.input-group {
  border-radius: 0.25rem;

  input:-webkit-autofill {
    background: $input-bg;
  }

  .form-control {
    border: none;

    &:not(:first-child) {
      padding-left: 0;
    }

    &:not(:last-child) {
      padding-right: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .input-group-prepend, .input-group-append {
    img, svg {
      max-height: 19px;
      width: auto;
    }
  }

  &:focus-within {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.42);
  }

  &.disabled {
    .form-control {
      background: $input-disabled-bg;
    }

    .input-group-prepend, .input-group-append {
      .input-group-text {
        background: $input-disabled-bg;
      }
    }
  }

  &.invalid {
    .form-control {
      background-color: #ffe6e6;
      border: 1px solid #d79f9f;

      &:not(:first-child) {
        border-left: none;
      }

      &:not(:last-child) {
        border-right: none;
      }
    }

    .input-group-prepend, .input-group-append {
      .input-group-text {
        background-color: #ffe6e6;
        border: 1px solid #d79f9f;
      }

      &:first-child {
        .input-group-text {
          border-right: none;
        }
      }

      &:last-child {
        .input-group-text {
          border-left: none;
        }
      }
    }
  }
}

.input-group-text {
  padding: $input-padding-y $input-padding-x-sm;
}

.form-control-inline-button {
  position: relative;

  input {
    padding-right: 120px;
    text-overflow: ellipsis;
  }

  .btn {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;

    display: flex;
    align-items: center;

    border-radius: $border-radius;
  }
}

.invalid {
  .MuiInput-root {
    background-color: #ffe6e6;
    border: 1px solid #d79f9f;
  }
}
